import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6f57124a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _dc499c50 = () => interopDefault(import('../pages/cart.vue' /* webpackChunkName: "pages/cart" */))
const _315f9e57 = () => interopDefault(import('../pages/categories/index.vue' /* webpackChunkName: "pages/categories/index" */))
const _7f138d87 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _a5791dd8 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _38feb561 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _33e8ff42 = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _d4924cfe = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _5bf37d38 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _4f065bdb = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _f4871fee = () => interopDefault(import('../pages/restore/index.vue' /* webpackChunkName: "pages/restore/index" */))
const _274a1060 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _09555442 = () => interopDefault(import('../pages/tickets.vue' /* webpackChunkName: "pages/tickets" */))
const _6ef52679 = () => interopDefault(import('../pages/profile/tickets.vue' /* webpackChunkName: "pages/profile/tickets" */))
const _4aa3d48d = () => interopDefault(import('../pages/categories/_cid/index.vue' /* webpackChunkName: "pages/categories/_cid/index" */))
const _3ddd3cf2 = () => interopDefault(import('../pages/events/_eid/index.vue' /* webpackChunkName: "pages/events/_eid/index" */))
const _631db637 = () => interopDefault(import('../pages/groups/_id.vue' /* webpackChunkName: "pages/groups/_id" */))
const _57d9b5c8 = () => interopDefault(import('../pages/payment/_hash.vue' /* webpackChunkName: "pages/payment/_hash" */))
const _a7d69a8e = () => interopDefault(import('../pages/restore/_key.vue' /* webpackChunkName: "pages/restore/_key" */))
const _40fb7687 = () => interopDefault(import('../pages/events/_eid/tickets.vue' /* webpackChunkName: "pages/events/_eid/tickets" */))
const _6da07102 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _6f57124a,
    name: "index___en"
  }, {
    path: "/lt",
    component: _6f57124a,
    name: "index___lt"
  }, {
    path: "/ru",
    component: _6f57124a,
    name: "index___ru"
  }, {
    path: "/en/cart",
    component: _dc499c50,
    name: "cart___en"
  }, {
    path: "/en/categories",
    component: _315f9e57,
    name: "categories___en"
  }, {
    path: "/en/cookies",
    component: _7f138d87,
    name: "cookies___en"
  }, {
    path: "/en/events",
    component: _a5791dd8,
    name: "events___en"
  }, {
    path: "/en/login",
    component: _38feb561,
    name: "login___en"
  }, {
    path: "/en/logout",
    component: _33e8ff42,
    name: "logout___en"
  }, {
    path: "/en/payment",
    component: _d4924cfe,
    name: "payment___en"
  }, {
    path: "/en/profile",
    component: _5bf37d38,
    name: "profile___en"
  }, {
    path: "/en/register",
    component: _4f065bdb,
    name: "register___en"
  }, {
    path: "/en/restore",
    component: _f4871fee,
    name: "restore___en"
  }, {
    path: "/en/search",
    component: _274a1060,
    name: "search___en"
  }, {
    path: "/en/tickets",
    component: _09555442,
    name: "tickets___en"
  }, {
    path: "/lt/cart",
    component: _dc499c50,
    name: "cart___lt"
  }, {
    path: "/lt/categories",
    component: _315f9e57,
    name: "categories___lt"
  }, {
    path: "/lt/cookies",
    component: _7f138d87,
    name: "cookies___lt"
  }, {
    path: "/lt/events",
    component: _a5791dd8,
    name: "events___lt"
  }, {
    path: "/lt/login",
    component: _38feb561,
    name: "login___lt"
  }, {
    path: "/lt/logout",
    component: _33e8ff42,
    name: "logout___lt"
  }, {
    path: "/lt/payment",
    component: _d4924cfe,
    name: "payment___lt"
  }, {
    path: "/lt/profile",
    component: _5bf37d38,
    name: "profile___lt"
  }, {
    path: "/lt/register",
    component: _4f065bdb,
    name: "register___lt"
  }, {
    path: "/lt/restore",
    component: _f4871fee,
    name: "restore___lt"
  }, {
    path: "/lt/search",
    component: _274a1060,
    name: "search___lt"
  }, {
    path: "/lt/tickets",
    component: _09555442,
    name: "tickets___lt"
  }, {
    path: "/ru/cart",
    component: _dc499c50,
    name: "cart___ru"
  }, {
    path: "/ru/categories",
    component: _315f9e57,
    name: "categories___ru"
  }, {
    path: "/ru/cookies",
    component: _7f138d87,
    name: "cookies___ru"
  }, {
    path: "/ru/events",
    component: _a5791dd8,
    name: "events___ru"
  }, {
    path: "/ru/login",
    component: _38feb561,
    name: "login___ru"
  }, {
    path: "/ru/logout",
    component: _33e8ff42,
    name: "logout___ru"
  }, {
    path: "/ru/payment",
    component: _d4924cfe,
    name: "payment___ru"
  }, {
    path: "/ru/profile",
    component: _5bf37d38,
    name: "profile___ru"
  }, {
    path: "/ru/register",
    component: _4f065bdb,
    name: "register___ru"
  }, {
    path: "/ru/restore",
    component: _f4871fee,
    name: "restore___ru"
  }, {
    path: "/ru/search",
    component: _274a1060,
    name: "search___ru"
  }, {
    path: "/ru/tickets",
    component: _09555442,
    name: "tickets___ru"
  }, {
    path: "/en/profile/tickets",
    component: _6ef52679,
    name: "profile-tickets___en"
  }, {
    path: "/lt/profile/tickets",
    component: _6ef52679,
    name: "profile-tickets___lt"
  }, {
    path: "/ru/profile/tickets",
    component: _6ef52679,
    name: "profile-tickets___ru"
  }, {
    path: "/en/categories/:cid",
    component: _4aa3d48d,
    name: "categories-cid___en"
  }, {
    path: "/en/events/:eid",
    component: _3ddd3cf2,
    name: "events-eid___en"
  }, {
    path: "/en/groups/:id?",
    component: _631db637,
    name: "groups-id___en"
  }, {
    path: "/en/payment/:hash",
    component: _57d9b5c8,
    name: "payment-hash___en"
  }, {
    path: "/en/restore/:key",
    component: _a7d69a8e,
    name: "restore-key___en"
  }, {
    path: "/lt/categories/:cid",
    component: _4aa3d48d,
    name: "categories-cid___lt"
  }, {
    path: "/lt/events/:eid",
    component: _3ddd3cf2,
    name: "events-eid___lt"
  }, {
    path: "/lt/groups/:id?",
    component: _631db637,
    name: "groups-id___lt"
  }, {
    path: "/lt/payment/:hash",
    component: _57d9b5c8,
    name: "payment-hash___lt"
  }, {
    path: "/lt/restore/:key",
    component: _a7d69a8e,
    name: "restore-key___lt"
  }, {
    path: "/ru/categories/:cid",
    component: _4aa3d48d,
    name: "categories-cid___ru"
  }, {
    path: "/ru/events/:eid",
    component: _3ddd3cf2,
    name: "events-eid___ru"
  }, {
    path: "/ru/groups/:id?",
    component: _631db637,
    name: "groups-id___ru"
  }, {
    path: "/ru/payment/:hash",
    component: _57d9b5c8,
    name: "payment-hash___ru"
  }, {
    path: "/ru/restore/:key",
    component: _a7d69a8e,
    name: "restore-key___ru"
  }, {
    path: "/en/events/:eid/tickets",
    component: _40fb7687,
    name: "events-eid-tickets___en"
  }, {
    path: "/lt/events/:eid/tickets",
    component: _40fb7687,
    name: "events-eid-tickets___lt"
  }, {
    path: "/ru/events/:eid/tickets",
    component: _40fb7687,
    name: "events-eid-tickets___ru"
  }, {
    path: "/en/:slug",
    component: _6da07102,
    name: "slug___en"
  }, {
    path: "/lt/:slug",
    component: _6da07102,
    name: "slug___lt"
  }, {
    path: "/ru/:slug",
    component: _6da07102,
    name: "slug___ru"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
